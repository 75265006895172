const apps = [
  {
    path: '/apps/:uuid/workspace',
    name: 'app.workspace',
    component: () => import('@/views/app/AppWorkSpace.vue'),
  },
  {
    path: '/apps/:uuid/workspace/models',
    name: 'app.workspace.models',
    component: () => import('@/views/app/workspace/model/ModelWorkspace.vue'),
  },
]

export default apps
