<template>
  <div /></template>

<script>
export default {
  name: 'ModelsActions',
}
</script>

<style scoped>

</style>
