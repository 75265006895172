export default {
  namespaced: true,
  state: {
    models: [],
    current_model: null,
  },
  getters: {
    currentModel: state => state.current_model,
    models: state => state.models,
  },
  mutations: {
    // eslint-disable-next-line no-shadow
    SET_ALL_MODELS(state, payload) {
      state.models = payload
    },
    SET_CURRENT_MODEL(state, payload) {
      state.current_model = payload
    },
  },
  actions: {
    getModels: async ({ commit, state }, payload) => {
      try {
        const force = payload.force || false
        if (state.models.length > 0 && !force) {
          return state.models
        }
        const data = await window.http.get(`apps/${payload.app}/models?fields=uuid,name`)
        commit('SET_ALL_MODELS', data)
        return data
      } catch (error) {
        return error
      }
    },
    setModels: async ({ commit }, data) => {
      commit('SET_ALL_MODELS', data)
    },

    getModel: async ({ commit }, uuid) => {
      const data = await window.http.get(`models/${uuid}`)
      if (data.error) {
        return null
      }
      commit('SET_CURRENT_MODEL', data)
      return data
    },
  },
}
