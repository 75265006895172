<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :vid="label"
    :rules="rules"
  >
    <b-form-group
      :label="label"
      :label-for="name"
    >
      <v-select
        key="value"
        v-model="inputValue"
        :name="name"
        :placeholder="placeholder"
        label="text"
        :options="options"
        :state="errors.length > 0 ? false:null"
        :disabled="disabled"
        :readonly="readonly"
        :filterable="false"
        @search="fetchOptions"
      >
        <div slot="no-options">
          Nenhum(a) {{ label }} encontrado(a).
        </div>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'
import debounce from 'lodash/debounce'

export default {
  name: 'SAutoComplete',
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },

    rules: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    endpoint: {
      type: String,
      required: true,
      default: '',
    },
    valueField: {
      type: String,
      required: true,
      default: 'uuid',
    },
    textField: {
      type: String,
      required: true,
      default: 'uuid',
    },
  },
  data() {
    return {
      inputValue: '',
      selected: null,
      required,
      options: [],
    }
  },
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
      this.$emit('onChange', val)
    },
  },
  methods: {

    async fetchOptions(search, loading) {
      this.options = []
      if (search.length < 2) {
        return
      }
      loading(true)
      const data = await this.resourceSearch(this.endpoint + search, this)
      loading(false)
      this.options = data
    },
    resourceSearch: debounce(async function (url) {
      // eslint-disable-next-line no-invalid-this
      const self = this

      self.loading = true
      const result = await this.$http.get(url)
      const response = result.data || result

      const itens = []
      for (const data of response) {
        itens.push({
          value: data[this.valueField],
          text: data[this.textField],
        })
      }
      this.options = itens
      return itens
    }, 200),
  },

}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
