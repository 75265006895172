<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <Loader :visible="loading" />
    <component :is="layout">
      <router-view />
    </component>
    <b-modal
      id="modal-xl"
      ref="update_modal"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-footer
      centered
      size="sm"
      title="Nova versão da plataforma"
    >
      <b-row>
        <b-col md="2">

          <feather-icon
            icon="InfoIcon"
            size="50"
          />
        </b-col>
        <b-col md="10">
          <h4>Antes de continuar, atualize para uma melhor experiência.</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          style="text-align: center"
          class="full-width"
        >
          <b-button
            variant="outline-primary"
            class="btn-icon mr-2"
            @click="update"
          >
            <feather-icon
              icon="ToolIcon"
              class="mr-50"
            />
            Atualizar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { mapActions, mapState } from 'vuex'
import { watch } from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import Loader from '@/components/i2/http/loader/Loader.vue'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    Loader,

  },
  data: () => ({
    registration: null,
    isRefresh: false,
    refreshing: false,
  }),
  created() {
    document.addEventListener(
      'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true },
    )
    navigator.serviceWorker.addEventListener(
      'controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      },
    )
  },
  async mounted() {
    await this.me()
  },
  methods: {
    ...mapActions('auth', ['me']),
    appUpdateUI(e) {
      this.registration = e.detail
      this.isRefresh = true
      this.$refs.update_modal.show()
    },
    update() {
      this.isRefresh = false
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    ...mapState('loader', ['loading']),
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>
