import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import password from '@/router/password'
import users from '@/router/users'
import types from '@/router/types'
import clients from '@/router/clients'
import apps from '@/router/app'
import models from '@/router/model'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...types,
    ...clients,
    ...apps,
    ...models,
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'page-error-404' || to.name === 'login' || to.name === 'perdi-minha-senha'
    || to.name === 'recuperar-senha') {
    return next()
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn() && to.name !== 'login') {
      // window.location.href = '/'
      return false
    }
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
